import { createRouter, createWebHistory } from 'vue-router'

// import front from '@/components/FrontLayout.vue'

const routes = [
  {
    path: '/My-profile',
    name: 'MyProfile',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/MyProfile.vue')
        }],
  },
  {
    path: '/Profile-Edit/:id',
    name: 'ProfileEdit',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/EditProfile.vue')
        }],
  },
  {
    path: '/Respond-club',
    name: 'RespondClub',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Respond/RespondClub.vue')
        }],
  },
  {
    path: '/Respond-technician',
    name: 'RespondTechnician',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Respond/RespondTechnician.vue')
        }],
  },
  {
    path: '/Report/sos/sc',
    name: 'ReportSOSSC',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SOS/Report-sos-sc.vue')
        }],
  },
  {
    path: '/Report/sos/rj',
    name: 'ReportSOSRJ',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SOS/Report-sos-rj.vue')
        }],
  },
  {
    path: '/Timeline/sos/:id',
    name: 'TimelineSOS',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SOS/Timeline-sos.vue')
        }],
  },
  {
    path: '/SuperAdmin',
    name: 'SuperAdmin',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/SuperAdmin.vue')
        }],
  },
  {
    path: '/User-technician',
    name: 'Technician',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Technician/Technician.vue')
        }],
  },
  {
    path: '/Technician-Detail/:id',
    name: 'TechnicianDetail',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Technician/TechnicianDetail.vue')
        }],
  },
  {
    path: '/Technician-Edit/:id',
    name: 'TechnicianEdit',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Technician/TechnicianEdit.vue')
        }],
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Users/User.vue')
        }],
  },
  {
    path: '/User-Detail/:id',
    name: 'UserDetail',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Users/UserDetail.vue')
        }],
  },
  {
    path: '/User-Edit/:id',
    name: 'UserEdit',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Users/EditUser.vue')
        }],
  },
  {
    path: '/Club',
    name: 'Club',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Club/Club.vue')
        }],
  },
  {
    path: '/Club-Detail/:id/:user_id',
    name: 'ClubDetail',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Club/ClubDetail.vue')
        }],
  },
  {
    path: '/Club-Edit/:id/:user_id',
    name: 'ClubEdit',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Club/EditClub.vue')
        }],
  },
  {
    path: '/Car-Data',
    name: 'CarData',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Car/CarData.vue')
        }],
  },
  {
    path: '/Problem-Data',
    name: 'ProblemData',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/Problem.vue')
        }],
  },
  {
    path: '/Pay-Data',
    name: 'PayData',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/Pay.vue')
        }],
  },
  {
    path: '/Car-Edit/:id',
    name: 'CarEdit',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Car/EditCar.vue')
        }],
  },
  {
    path: '/Dashboard',
    name: 'DashBoard', 
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/SuperAdmin/Dashboard.vue')
        }],
  },
  {
    path: '/Notification',
    name: 'Notification',
    component: () => import('../components/AdminLayout.vue'),
        children: [{
            path: '',
            component: () => import('../components/Notification.vue')
        }],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/SuperAdmin/Login.vue')
  },
  {
    path: '/',
    name: 'Home',  
    component: () => import('../components/FrontLayout.vue'),
        children: [{
            path: '',
            component: () => import('@/views/HomeView.vue')
        }], 
  },
  {
    path: '/about',
    name: 'About',  
    component: () => import('../components/FrontLayout.vue'),
        children: [{
            path: '',
            component: () => import('@/views/AboutView.vue')
        }], 
  },
  {
    path: '/team',
    name: 'Team',  
    component: () => import('../components/FrontLayout.vue'),
        children: [{
            path: '',
            component: () => import('@/views/TeamView.vue')
        }], 
  },
  {
    path: '/contact',
    name: 'Contact',  
    component: () => import('../components/FrontLayout.vue'),
        children: [{
            path: '',
            component: () => import('@/views/ContactView.vue')
        }], 
  },
  {
    path: '/privacy_policy',
    name: 'privacy_policy',  
    component: () => import('../components/PrivacyPolicy.vue'), 
  },
  {
    path: '/terms_conditions',
    name: 'terms_conditions',  
    component: () => import('../components/TermsConditions.vue'), 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
